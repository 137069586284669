import { addPropertyControls, ControlType, motion } from "framer"

/**
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 */
export default function RippleTiny({ size, speed, delay, stroke, color }) {
    // const strokeSize = size / 16
    const ripple = {
        width: size,
        height: size,
        backgroundColor: "transparent",
        borderRadius: "50%",
        position: "absolute",
        boxShadow: `0 0 0 ${stroke}px ${color}`,
    }
    const transition = {
        ease: "linear",
        duration: speed,
        loop: Infinity,
        delay: 0,
    }
    const animation = {
        scale: [0, 4],
        opacity: [0.5, 0],
    }
    return (
        <div style={parent}>
            <motion.div
                style={{
                    ...ripple,
                    backgroundColor: color,
                    opacity: 1,
                }}
            />
            <motion.div
                style={ripple}
                animate={animation}
                transition={{ ...transition }}
            />
            <motion.div
                style={ripple}
                animate={animation}
                transition={{ ...transition, delay: delay }}
            />
            <motion.div
                style={ripple}
                animate={animation}
                transition={{ ...transition, delay: delay * 1.5 }}
            />
            <motion.div
                style={ripple}
                animate={animation}
                transition={{ ...transition, delay: delay * 2 }}
            />
        </div>
    )
}

RippleTiny.defaultProps = {
    size: 3,
    speed: 2,
    stroke: 0.25,
    delay: 1,
    color: "#0AF",
}

addPropertyControls(RippleTiny, {
    size: { type: ControlType.Number, min: 2, max: 200 },
    speed: {
        type: ControlType.Number,
        min: 0,
        max: 20,
        title: "Time",
        step: 0.1,
    },
    delay: {
        type: ControlType.Number,
        min: 0,
        max: 10,
        title: "Delay",
        step: 0.1,
    },
    stroke: { type: ControlType.Number, min: 0, max: 10, step: 0.1 },
    color: { type: ControlType.Color },
})

const parent = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}
